import { template as template_0d4d3c2d2ab649c68995fb43b6998ac5 } from "@ember/template-compiler";
const FKText = template_0d4d3c2d2ab649c68995fb43b6998ac5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
