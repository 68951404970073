import { template as template_e5427e67aa4c478595f2ef5b3e9fa584 } from "@ember/template-compiler";
const WelcomeHeader = template_e5427e67aa4c478595f2ef5b3e9fa584(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
