import { template as template_4c98964ebc494f7ca436ba64d22afc8a } from "@ember/template-compiler";
const FKLabel = template_4c98964ebc494f7ca436ba64d22afc8a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
