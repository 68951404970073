import { template as template_78caff46471e4545b5c1da9fa33ff987 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_78caff46471e4545b5c1da9fa33ff987(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
