import { template as template_a1286537949e41049e5e1c96a64aadd2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a1286537949e41049e5e1c96a64aadd2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
