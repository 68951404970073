import { template as template_b2a0542b375d42c2b8bdb5980d5d55db } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b2a0542b375d42c2b8bdb5980d5d55db(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
